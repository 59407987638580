import React from "react";
import "./FullMotheCalender.css";
import "./Calendar.css";
import { ChevronLeftFilled, ChevronRightFilled } from "@fluentui/react-icons";

export const FullMonthCalendar = ({ customRes, onChange, selectedDate }) => {
      const currentDate = new Date();

      // Helper: Get the start and end of the month for a given date
      const getMonthRange = (date) => {
            const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            return { startOfMonth, endOfMonth };
      };

      const { startOfMonth, endOfMonth } = getMonthRange(selectedDate);

      // Generate all days of the current month
      const generateDaysInMonth = () => {
            const days = [];

            // Add empty placeholders for the days before the first day of the month
            const firstDayOfWeek = startOfMonth.getDay(); // 0 (Sunday) to 6 (Saturday)
            for (let i = 0; i < firstDayOfWeek; i++) {
                  days.push(null); // Placeholder for empty days
            }

            // Add actual days of the month
            for (let i = 1; i <= endOfMonth.getDate(); i++) {
                  const day = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), i);
                  days.push(day);
            }

            return days;
      };

      const daysInMonth = generateDaysInMonth();

      // Format the month range as "Month YYYY"
      const formatMonthRange = (date) => {
            return date.toLocaleString("default", { month: "long", year: "numeric" });
      };

      // Handle selecting a date
      const selectDate = (date) => {
            if (onChange) {
                  onChange(date);
            }
      };

      // Go to the previous month
      const prevMonth = () => {
            const prevMonthDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1);
            const today = new Date();

            // Check if the resulting month is the current month
            if (
                  prevMonthDate.getFullYear() === today.getFullYear() &&
                  prevMonthDate.getMonth() === today.getMonth()
            ) {
                  // If it's the current month, set to today's date
                  selectDate(today);
            } else {
                  // Otherwise, set to the first day of the previous month
                  selectDate(prevMonthDate);
            }
      };


      // Go to the next month
      const nextMonth = () => {
            const nextMonthDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
            selectDate(nextMonthDate);
      };

      // Disable past months
      const isPrevMonthDisabled = () => {
            const prevMonthDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1);
            return prevMonthDate < new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      };

      return (
            <div className="calendar-container">
                  {/* Month Navigation */}
                  <div className="month-navigation">
                        <ChevronLeftFilled
                              className={`navigation-icon ${isPrevMonthDisabled() ? "disabled" : ""}`}
                              onClick={!isPrevMonthDisabled() ? prevMonth : null}
                        />
                        <span className="month-range">{formatMonthRange(selectedDate)}</span>
                        <ChevronRightFilled className="navigation-icon" onClick={nextMonth} />
                  </div>

                  {/* Render Calendar */}
                  <div id="calendar">
                        {/* Render header with days of the week */}
                        <div className="header">
                              {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day, index) => (
                                    <div key={index} className="header-day">
                                          {day}
                                    </div>
                              ))}
                        </div>

                        {/* Render days in the current month */}
                        <div className="month">
                              {daysInMonth.map((day, index) => {
                                    const isSelected = day && selectedDate.toDateString() === day.toDateString();
                                    const isPast = day && day < new Date().setHours(0, 0, 0, 0); // Check if the day is in the past
                                    const backgroundColor = customRes?.button_colour || "#B58E57";
                                    const color = customRes?.non_highlighted_text || "white";

                                    return (
                                          <div className="day-container" key={index}>
                                                {day ? (
                                                      <div
                                                            style={{
                                                                  backgroundColor: isSelected ? backgroundColor : "transparent",
                                                                  color: isSelected ? color : isPast ? "#ccc" : "inherit",
                                                                  border: isSelected ? `2px solid ${backgroundColor}` : "none",
                                                                  pointerEvents: isPast ? "none" : "auto",
                                                                  cursor: isPast ? "not-allowed" : "pointer",
                                                            }}
                                                            className={`day day_round ${isPast ? "disabled" : ""}`}
                                                            onClick={() => !isPast && selectDate(day)}
                                                      >
                                                            {day.getDate()}
                                                      </div>
                                                ) : (
                                                      <div className="placeholder"></div>
                                                )}
                                          </div>
                                    );
                              })}
                        </div>
                  </div>
            </div>
      );
};





