// specialRequirementsOptions.js

export const specialRequirementsOptions = [
      { label: "Birthday - please advise name in message", value: "Birthday - please advise name in message" },
      { label: "Farewell", value: "Farewell" },
      { label: "Anniversary", value: "Anniversary" },
      { label: "Engagement", value: "Engagement" },
      { label: "Graduation", value: "Graduation" },
      { label: "Celebration", value: "Celebration" },
      { label: "X'Mas", value: "X'Mas" },
      { label: "Wedding", value: "Wedding" },
      { label: "Highchair", value: "Highchair" },
      { label: "Pram", value: "Highchair" },
      { label: "Allergy - please specify in message", value: "Allergy - please specify in message" },
      { label: "Dietary Requirement - please specify in message", value: "Dietary Requirement - please specify in message" },
      { label: "Disability Access", value: "Disability Access" },
      { label: "Hen's Night", value: "Hen's Night" },
      { label: "Buck's Night", value: "Buck's Night" },
];


export const NumberofGuests = [
      ...Array.from({ length: 10 }, (_, idx) => ({
            label: `${idx + 1}`,
            value: `${idx + 1}`,
      })),
      { label: "10+", value: "10+" },
];

