import React, { useEffect } from "react";
import { useHistory, useParams ,useLocation} from "react-router-dom";
import "./Logo.css";
import { connect } from "react-redux";
import { setCustome, getCustomData, getCMSData, setCMS } from "../../Store";
import { Helmet } from "react-helmet";
import { extractHostname } from "../../Customer/CommonComponet/UrlUtils/urlUtils";
import { useIsMobile, useIsTablet } from "../../Customer/CommonComponet/MediaQueries/mediaQueries";


function Logo(props) {
  const { setCustome, getCustomData, customRes ,cmsRes , getCMSData } = props;

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  let history = useHistory();
  var pathname = window.location.href;
  var pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  var url = pathnamesplits[1];
  var url2 = pathnamesplits[4];

  const { tableId } = useParams();
  var urlTableId = String(tableId);

  let hostname = extractHostname();

  const bbbb = "thegrill.mypreview.xyz";

  
  
  useEffect(() => {
    setCustome(hostname);
    getCustomData();
    getCMSData();
  }, []);

  useEffect(() => {
    if(customRes?.icon_url){
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${customRes?.icon_url}`;
  }
  }, [customRes?.icon_url]);

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const TableNo = query.get("no");

  const style = {
    cursor: "pointer",
    backgroundColor: "transparent",
    height: "45px",
    objectFit : "cover",
    borderRadius : "7px"
  }

  return (
    <React.Fragment>
      <Helmet>
                <meta charSet="utf-8" />
                <title>{customRes?.restaurant_name || "Kitchen Parameter" }</title>
                <link rel="canonical" href="http://example.com" />
      </Helmet>
      {url2 === "payment_success" || url === "success_dinein" ||
      url2 === "payment_failed" ||
      url === "voucher" || 
      urlTableId > 0 ? (
        <div
          className="hover14 column"
          style={{ display: "inline-block" , marginLeft : isMobile && 10  }}
        >
            <img
              alt="Logo"
              className="mx-3"
              src={customRes?.logo_url || "/assets/media/logos/Logo_Dark.png"}
              style={style}
            />
        </div>
      ) : (
        <div
          onClick={() => TableNo ? history.push(`/${url}/menu?no=${TableNo}`) : history.push(`/`)}
          className="hover14 column dfsdfsdfs"
          style={{ display: "inline-block" ,marginLeft : !isMobile && 10 }}
        >
            <img
              alt="Logo"
              className="mx-3"
              src={customRes?.logo_url || "/assets/media/logos/Logo_Dark.png"}
              style={style}
            />
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,

  cmsRes: state.custom.cmsRes,
  cmsUrl: state.custom.cmsUrl,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    setCMS: (url) => dispatch(setCMS(url)),
    getCMSData: () => dispatch(getCMSData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
